// ######################################## //
// ## COLORS.SCSS ## //
// ######################################## //

// Black (Gray) Color Shades
$black: #000000;
$gray-1: #111111;
$gray-2: #222222;
$gray-3: #333333;
$gray-4: #444444;
$gray-5: #555555;
$gray-6: #666666;
$gray-7: #777777;
$gray-8: #888888;
$gray-9: #999999;
$gray-a: #aaaaaa;
$gray-b: #bbbbbb;
$gray-c: #cccccc;
$gray-d: #dddddd;
$gray-e: #eeeeee;
$gray-f5: #f5f5f5;
$gray-fa: #fafafa;
$gray-a2: #a2a2a2;
$white: #ffffff;
$bgColor: #00236e;
$textColor: #f5f0eb;

// Color Shades (Theme Based - Xclusive)
$theme-primary: #006eef;
$theme-secondary: #0f1938;
$theme-success: #61b23b;
$theme-info: #9c6dff;
$theme-warning: #ff8238;
$theme-orange: #ff6e6e;
$theme-danger: #e24c4b;
$theme-yellow: #ea9d04;
$theme-cyan: #35d2cc;
$theme-pink: #c83375;
$theme-link: #004b8f;
$theme-purple: #b469ff;
