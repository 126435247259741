// variables
@import "./variables/colors";
@import "./variables/mixins";

// theme styles files
@import "./texts";
@import "./xtras";

// custom css
* {
  font-family: "ObjectSans-Regular", sans-serif !important;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-size: 14px;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bgColor;
}

a {
  text-decoration: none !important;
}
