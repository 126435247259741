// ######################################## //
// ## TEXTS.SCSS ## //
// ######################################## //

// Font Primary

@font-face {
  font-family: "ObjectSans-Regular";
  font-weight: 400;
  font-style: normal;
  src: url("../assets/fonts/ObjectSans-Regular.otf"); /* IE9 Compat Modes */
  src: url("../assets/fonts/ObjectSans-Regular.otf") format("truetype");
}

@font-face {
  font-family: "ObjectSans-Bold";
  font-style: normal;
  font-weight: bold;
  src: url("../assets/fonts/ObjectSans-Bold.otf"); /* IE9 Compat Modes */
  src: url("../assets/fonts/ObjectSans-Bold.otf") format("truetype");
}
