@import "../../styles/variables/colors";
@import "../../styles/variables/mixins";

.main-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  // heading-section
  .heading-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-grow: 2;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include mobile {
      align-items: center;
    }

    .title {
      font-size: 80px;
      letter-spacing: 4px;
      line-height: 1;
      color: $textColor;
      text-transform: uppercase;
      font-weight: normal;
      margin: 0;
      display: flex;
      flex-direction: column;

      @include mobile {
        font-size: 40px;
      }

      @include tablet {
        font-size: 55px;
      }
    }

    .sub-title {
      font-size: 41px;
      letter-spacing: 34.96px;
      line-height: 1;
      color: $textColor;
      font-weight: normal;
      margin: 5px 0 0 33px;
      display: block;

      @include mobile {
        font-size: 30px;
        letter-spacing: 12.2px;
        margin-left: 6px;
      }

      @include tablet {
        font-size: 45px;
      }
    }
  }

  // Footer style
  .footer {
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    bottom: 82px;
    text-align: center;

    @include mobile {
      bottom: 50px;
    }

    .copyright-text {
      font-size: 16px;
      letter-spacing: 0px;
      color: $white;
    }
  }
}
